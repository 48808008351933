<template>
  <div class="home">
    <!-- 云呼挂载元素id -->
    <div id="workbench" :class="{'workbenchBorder':workbenchBorder}"></div>   
  </div>
</template>

<script>
export default {
    name: 'HomeView',
    data() {
        return {
            openWbShow:false, // 云呼按钮的显示
            login:false, // 云呼按钮是登录状态
            openStatus:false, // 控制面板的显示
            workbenchBorder:false, // 面板的边框
            visibleWorkbench:false, // 来电的时候工作台弹窗的显示
        }
    },
    mounted() { 
        // this.getUserInfo();
        this.initWorkBench()
    },
    methods:{
        getUserInf(){
            // let self = this;
            // self.$axios.post("后台给的用户相关的接口").then((res) => {
            //     // console.log(res.data);
            //     // user.isCall属性来判断是给允许进入云呼叫拨打电话,0：云呼按钮不可见；1：可见云呼按钮，但不能进行外：能够实现外呼功能
            //     // 判断当前用户的权限 有权限的话显示云呼按钮 
            //     // 判断云呼登录状态 如果已登录 按钮蓝色 初始化initWorkBench，如果未登录的话是灰色按钮，点击去登录的界面（后台给我的阿里云的子账户登陆页面，这是后台配置好的）
            //     if(res.data.data.user.isCall==null || res.data.data.user.isCall == 0{
            //         self.openWbShow = false;
            //     }else if(res.data.data.user.isCall == 1){
            //         self.openWbShow = true; //云呼按钮可以显示
            //         if(res.data.data.user.aliToken){
            //             // 判断是不是有aliToken，有的话云呼可以上线，初始化
            //             self.login = true;
            //             self.initWorkBench();
            //         }else{  
            //             // 可以显示云呼按钮，但不能使得上线，上线按钮为灰色
            //             self.login = false;
            //         }
            //     }
            // });
        },
        //云呼相关初始化
        initWorkBench(){
            let self = this;
            window.workbench = new window.WorkbenchSdk({
                dom: 'workbench', // 挂载的元素
                width: '280px', // 面板的宽
                height: '500px', // 面板的高
                instanceId: 'demo-1487688465581258', //后台申请的id，必选配置
                regionId: 'cn-shanghai',//云呼服务器部署的集群，如果没有特殊说明，为'cn-shanghai'
                // accessKeyId:'LTAI5tGCbq1MZN3VYRtPrQSX' ,
                // accessKeySecret:'dYZtGl0xZGgfQDOpIiKkzUoWE0mt6e',
                // RPCEndPoint: '....',
                // apiVersion: '2.0.2',
                // ajaxOrigin:'https://ccc-v2.aliyun.com/workbench/demo-1487688465581258',
                ajaxOrigin:'http://jinyoucrm.95act.cn',
                ajaxPath: '/api/alibaba_clouds/demo',// 请求路径，默认为”/data/api.json”
                // ajaxMethod: 'post',//请求方式，post|get 默认post，
                afterCallRule: 15,  // 挂机后的话后处理时间
                // header: true,//是否展示头部（小休，下线，场外模式接听等），默认展示
                // defaultVisible:false,//默认是否展示面板，默认为true
                exportErrorOfApi: true,// Toast提示接口的错误信息、ApiName、ErrorCode、RequestId，当接口出现错误的，提供错误信息，便于后端排查问题。默认是false，可根据需要是否Toast错误消息。
                // moreActionList: ['mobilePhoneAnswer', 'break', 'offline'],
                // sideNavList: ['呼入', '呼出', '通话记录', '转接', '监听', '我的工作', '设备检测', '设置'],
                onInit: function () {
                    // window.workbench.register(); // 想实现自动上线在此注册
                    // window.workbench.changeVisible(true); //可以实现初始化后显示面板
                },
                onErrorNotify: function (error) {
                    console.warn(error)
                },
                onCallComing: function (connid,caller,calee,contactId,skillgroupId) {
                    // connid: 通话id;caller: 主叫号码;calee:被叫号码;contactId:录音id，通话记录查询需要的字段;skillgroupId:技能组id
                    eventBus.phone = caller; //将获取的号码，先赋值
                    eventBus.$emit("callComming",caller); // 传递给非父子组件 this.$emit()// 可以通过emit或者eventbus的方式外部触发事件。
                },
                onCallDialing: function (connid,caller,calee,contactId) {
                    console.log('------//去电、拨号振铃时触发------');
                    // console.log('这里是',connid,caller,calee,contactId);
                },
                onBeforeCallDialing: function(payload) {
                    console.log('------before call dialing------', payload);
                    payload.callback(); //必须执行callback，否则不会拨号
                },
                onStatusChange: function (code, lastCode, context) {
                    // console.log(code, lastCode, context);
                },
                onCallEstablish: function (connid, caller, calee, contactId) {
                    // console.log('这里是通话建立时触发的回调函数', connid, caller, calee, contactId)
                },
                onCallRelease: function (connid, caller, calee, contactId) {
                    // console.log('这里是通话结束时触发的回调函数', connid, caller, calee, contactId)
                },
                onHangUp(type){
                    // console.log("挂机");
                    eventBus.phone = ""; 
                },
                onStatusChange(code, lastCode, context){
                    // console.log(code, lastCode, context);
                }
            })
        },
        //初始化结束后，就可以根据自己的方式在页面中调用提供的api进行外呼等操作了
//          window.workbench.call('', '');//拨打电话
//  window.workbench.hangUp();//挂断电话
        // 云呼相关配置
        openWB(openStatus){
            //  点击出现云呼面板，再次点击面板消失
            if(openStatus == true){
                window.workbench.changeVisible(false);
                this.openStatus = false;
                this.workbenchBorder = false;
            }else{
                window.workbench.changeVisible(true);
                this.openStatus = true;
                this.workbenchBorder = true;
            }
        }
        // 修改标题及
    }
}
</script>
